import React, { useEffect, useState } from 'react'
import {getNews} from '../../httpService/news'
import Newscards from './newscards'


function Updates() {
    
    const [news,setNews]=useState([])
    const [api] = useState(process.env.REACT_APP_IMAGE_URI);
    const [error,setError]=useState(false)
    async function getnews(){
        const result=await getNews()
        //console.log(result)
        if(result.error)
        {
                
        }
        else{
            setNews(result.data)
        }
    
       
    }
    useEffect(()=>{
    getnews()
       
    },[])
  //console.log(news)
  return (
      <>
     { news.length!=0 &&  <>
    <div>
        <h1 className="categories-title" style={{color:'white',margin:'20px  50px'}}>Updates</h1>
    </div>
    <div className='row' style={{marginTop:'10px'}}>
    {news.map((x, i) =>
    <div key={i} className="flex-center mb-3 col-3" >
    <div className="category-artist text-decoration-none" style={{height:'222px',width:'200px',display:'grid'}}>
        <div className="artist-image" style={{maxWidth:'100%',height:'100%'}}>
            <img
                style={{width:'100%',height:'100%'}}
                alt=""
                src={`${`http://165.227.140.163/entertainme/public/storage/`+x.image}`}
                className=""
            />                                           
        </div>
    <div style={{display:'grid',margin:'0 9px'}}>
        <div style={{widt:'100%',display:'flex',justifyContent:'space-between',marginTop:'6px'}}>
            <div >
                <div className='newscard-category' style={{padding:'2px 5px'}}>
                    Category
                </div>
            </div>
            <div >
                <div className='newscard-time'>
                    
                </div>
            </div>
        </div>
        <div className='newscard-title-section' style={{textAlign:'start',marginBottom:'10px',marginTop:'13px'}}>
           <span className='newscard-title'>
                {x.title}
            </span> 
        </div>
    </div>
    </div>
    </div>
    )}
    </div>
    </>
}
</>
  )
}

export default Updates