import React, { useEffect, useState } from "react";
import { removeEvents } from "../../../httpService/event";

const Deletepopup = ({ id, setPopUp }) => {
  console.log(id);
  async function deleteevent(id) {
    const response = await removeEvents(id);
    if (response.error) {
    } else {
      setPopUp(false);
      window.location.href = "/history";
    }
  }

  return (
    <div
      id={`cart-modal${id}`}
      role="dialog"
      aria-labelledby="cart-modal"
      aria-describedby="cart-modal"
      className="modal fade"
      aria-hidden="true"
      aria-modal="true"
      tabIndex="-1"
    >
      <div className="modal-dialog modal-lg modal-dialog-centered">
        <span tabIndex="0"></span>
        <div
          role="document"
          id="cart-modal"
          tabIndex="-1"
          className="modal-content bg-transparent border-0"
        >
          <div
            id="cart-modal"
            className="modal-body book-modal-body border-normal"
          >
            <div className="additional-services my-3">
              <div className="flex-center">
                <p className="Bookingpopup-titleone">
                  Are you sure you want to delete this event?
                </p>{" "}
              </div>
              <div
                className="flex-center"
                style={{
                  margin: "auto",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <div className="col-3 flex-center">
                  <button
                    className="booking-pop-up-btn"
                    style={{ backgroundColor: "#2d2d2d" }}
                    onClick={() => setPopUp(false)}
                  >
                    Cancel
                  </button>
                </div>
                <div className="col-3 flex-center">
                  <button
                    className="booking-pop-up-btn"
                    onClick={() => deleteevent(id)}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Deletepopup;
