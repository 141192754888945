import axios from "axios";
import React, { useEffect, useState } from "react";
import { removeEvents } from "../../../httpService/event";
import ArtistCard from "./ArtistCard";
import { Swiper, SwiperSlide } from "swiper/react";

const Editpopup = ({ event, setOffer }) => {
  console.log(event, "event");
  const AcceptOffer = async (offerid) => {
    console.log(offerid);
    const response = await axios.post(
      "https://admin.entertainme.co/api/offer/status/" + offerid,
      {
        status: "Accepted",
      }
    );
    if (response.error) {
    } else {
      setOffer(false);
      window.location.href = "/history";
    }
  };
  const RejectOffer = async (offerid) => {
    const response = await axios.post(
      "https://admin.entertainme.co/api/offer/status/" + offerid,
      {
        status: "Rejected",
      }
    );
    if (response.error) {
    } else {
      setOffer(false);
      window.location.href = "/history";
    }
  };
  return (
    <div
      id={`cart-modal${event.id}`}
      role="dialog"
      aria-labelledby="cart-modal"
      aria-describedby="cart-modal"
      className="modal fade"
      aria-hidden="true"
      aria-modal="true"
      tabIndex="-1"
    >
      <div className="modal-dialog modal-lg modal-dialog-centered">
        <span tabIndex="0"></span>
        <div
          role="document"
          id="cart-modal"
          tabIndex="-1"
          className="modal-content bg-transparent border-0"
        >
          <div
            id="cart-modal"
            className="modal-body book-modal-body border-normal"
            style={{ padding: "0px" }}
          >
            <div className="additional-services my-3">
              <div>
                <p
                  className="Bookingpopup-titleone"
                  style={{ marginBottom: "33px" }}
                >
                  order Offers
                </p>{" "}
              </div>
              {event.offers.length > 0 ? (
                event?.offers?.map((offer, index) => {
                  return (
                    <div
                      key={index}
                      style={{ display: "block", flexDirection: "row" }}
                    >
                      <span className="history-card-reserved">
                        {offer?.description}
                      </span>
                      <Swiper
                        spaceBetween={2}
                        slidesPerView={"auto"}
                        style={{ marginTop: "19px" }}
                      >
                        <div className="swiper-wrapper">
                          {offer?.artists?.map((art, index) => {
                            return (
                              <SwiperSlide
                                key={index}
                                style={{ width: "fit-content" }}
                              >
                                <ArtistCard
                                  key={index}
                                  artist={art?.artist}
                                ></ArtistCard>
                              </SwiperSlide>
                            );
                          })}
                        </div>
                      </Swiper>
                      <div className="history-card-reserved">
                        <span>Total Price: </span>
                        {offer?.price} EGP
                      </div>
                      {offer?.status == "Rejected" && (
                        <div
                          className="history-card-reserved"
                          style={{ display: "flex", fontSize: "16px" }}
                        >
                          <span style={{ color: "red" }}>
                            Status : Rejected
                          </span>
                        </div>
                      )}
                      {offer?.status == "Accepted" && (
                        <div
                          className="history-card-reserved"
                          style={{ display: "flex", fontSize: "16px" }}
                        >
                          <span style={{ color: "green" }}>
                            Status : Accepted
                          </span>
                        </div>
                      )}
                      {offer?.status !== "Rejected" &&
                        offer?.status !== "Accepted" && (
                          <div
                            className="row"
                            style={{ display: "flex", textAlign: "center" }}
                          >
                            <div className="col-6">
                              <button
                                className="booking-pop-up-btn"
                                style={{ width: "165px" }}
                                onClick={() => AcceptOffer(offer?.id)}
                              >
                                Accept
                              </button>
                            </div>
                            <div className="col-6">
                              <button
                                className="booking-pop-up-btn"
                                style={{
                                  backgroundColor: "#2d2d2d",
                                  borderColor: "#FF671D",
                                  width: "165px",
                                }}
                                onClick={() => RejectOffer(offer?.id)}
                              >
                                Reject
                              </button>
                            </div>
                          </div>
                        )}
                    </div>
                  );
                })
              ) : (
                <div style={{ color: "red" }}>No Offers</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Editpopup;
