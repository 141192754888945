import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CategoriesActions } from "../../store/Categories";
const MusicRadio = ({setFilter,filters}) => {
  
  const dispatch = useDispatch();
  const { filter } = useSelector((state) => state.Categories);
  const [checked, setChecked] = React.useState(
    filter.mainstream === 1 ? "mainstream" : "alternative"
  );
  useEffect(() => {
    let Filter = { ...filter };
    console.log(filter)
    Filter.mainstream = checked === "mainstream" ? 1 : 0;
    dispatch(CategoriesActions.onChangeFilter(Filter));
  }, [checked]);
  useEffect(()=>{
    setFilter({...filters,mainstream:filter.mainstream})

  },[])
  return (
    <>
      <div
        className={`text-center font-noto h5 p-1 m-0 px-2
        ${checked === "alternative" ? "bg-normal text-light" : ""}`}
        onClick={() => {
          setFilter(prev=>({...prev,mainstream:0}))
          
          setChecked("alternative");
        }}
      >
        Alternative
      </div>
      <div
        className={`text-center font-noto h5 p-1 m-0
        ${checked === "mainstream" ? "bg-normal text-light" : ""}`}
        onClick={() => {
          setFilter(prev=>({...prev,mainstream:1}))
          setChecked("mainstream");
        }}
      >
        Mainstream
      </div>
    </>
  );
};

export default MusicRadio;
