import React from "react";

const Logo = ({ width, height }) => {
  return (
    <React.Fragment>
      <div>
        <svg
          width={width}
          height={height}
          viewBox="0 0 1219.366 135.691"
        >
          <g
            id="Group_45"
            data-name="Group 45"
            transform="translate(-246.183 -267.304)"
          >
            <g
              id="logo-m"
              data-name="logo-m"
              transform="translate(1246.454 269.101)"
            >
              <path
                id="Path_10"
                className="logo-m-line"
                data-name="Path 10"
                d="M728.231,391.731V275.142c0-4.335,2.451-6.972,6.783-6.972h9.423c4.524,0,6.785,2.637,6.785,6.972V391.731c0,4.335-2.262,6.972-6.785,6.972h-9.423C730.682,398.7,728.231,396.065,728.231,391.731Z"
                transform="translate(-728.231 -268.17)"
                fill="#ff671d"
              ></path>
              <path
                id="Path_11"
                className="logo-m-line"
                data-name="Path 11"
                d="M749.3,376.118V289.665c0-4.335,2.449-6.972,6.783-6.972h9.423c4.524,0,6.785,2.637,6.785,6.972v86.453c0,4.335-2.262,6.972-6.785,6.972h-9.423C751.751,383.09,749.3,380.452,749.3,376.118Z"
                transform="translate(-705.579 -252.557)"
                fill="#ff671d"
              ></path>
              <path
                id="Path_12"
                className="logo-m-line"
                data-name="Path 12"
                d="M770.373,391.731V275.142c0-4.335,2.448-6.972,6.783-6.972h9.423c4.524,0,6.785,2.637,6.785,6.972V391.731c0,4.335-2.262,6.972-6.785,6.972h-9.423C772.822,398.7,770.373,396.065,770.373,391.731Z"
                transform="translate(-682.927 -268.17)"
                fill="#ff671d"
              ></path>
            </g>
            <path
              id="Path_13"
              data-name="Path 13"
              d="M330.588,388.334c0,6.9-4.87,11.564-12.174,11.564H258.357c-7.508,0-12.174-5.478-12.174-12.782V280.191c0-7.306,4.667-12.782,12.174-12.782H317.4c7.3,0,12.174,4.667,12.174,11.564,0,6.7-4.87,11.363-12.174,11.363H269.72v30.229h38.347c7.1,0,12.376,4.667,12.376,11.566,0,7.1-5.275,11.768-12.376,11.768H269.72v33.072h48.693C325.717,376.971,330.588,381.638,330.588,388.334Z"
              transform="translate(0 0.113)"
              fill="#fff"
            ></path>
            <path
              id="Path_14"
              data-name="Path 14"
              d="M406.373,280.191V387.116c0,7.3-4.667,12.782-12.174,12.782a11.96,11.96,0,0,1-9.535-4.059l-62.9-81.968v73.245c0,7.3-4.87,12.782-12.176,12.782-7.505,0-12.172-5.478-12.172-12.782V280.191c0-7.306,4.667-12.782,12.172-12.782,4.667,0,7.51,2.231,10.552,5.883l61.884,80.956V280.191c0-7.306,4.868-12.782,12.172-12.782C401.707,267.409,406.373,272.885,406.373,280.191Z"
              transform="translate(55.081 0.113)"
              fill="#fff"
            ></path>
            <path
              id="Path_15"
              data-name="Path 15"
              d="M463.812,279.65c0,6.9-4.87,11.564-12.174,11.564h-28v96.579c0,7.3-4.665,12.782-12.172,12.782-7.3,0-12.174-5.478-12.174-12.782V291.214h-28c-7.1,0-12.174-4.667-12.174-11.564s5.071-11.564,12.174-11.564h80.346C458.942,268.086,463.812,272.753,463.812,279.65Z"
              transform="translate(121.41 0.841)"
              fill="#fff"
            ></path>
            <path
              id="Path_16"
              data-name="Path 16"
              d="M503.167,389.011c0,6.9-4.87,11.564-12.172,11.564H430.937c-7.508,0-12.174-5.478-12.174-12.782V280.868c0-7.3,4.667-12.782,12.174-12.782h59.041c7.3,0,12.174,4.667,12.174,11.564,0,6.7-4.87,11.363-12.174,11.363H442.3v30.231h38.347c7.1,0,12.376,4.667,12.376,11.564,0,7.1-5.275,11.768-12.376,11.768H442.3v33.072H491C498.3,377.648,503.167,382.315,503.167,389.011Z"
              transform="translate(185.531 0.841)"
              fill="#fff"
            ></path>
            <path
              id="Path_17"
              data-name="Path 17"
              d="M566.779,398.343C561.1,402.4,554,400.982,549.737,395.1l-15.623-22.724c-4.057-5.885-10.753-17.246-21.709-17.246H494.348v32.665c0,7.3-4.87,12.782-12.174,12.782-7.508,0-12.174-5.478-12.174-12.782V280.868c0-7.3,4.667-12.782,12.174-12.782h40.173c32.057,0,45.448,20.9,45.448,42.607a44.468,44.468,0,0,1-22.52,38.754,48.73,48.73,0,0,1,9.738,10.753l14.608,21.1C573.475,387.183,572.259,394.286,566.779,398.343Zm-22.927-86.432c0-9.941-5.275-21.1-24.347-21.1H494.348v43.014h24.143C539.187,333.823,543.852,321.853,543.852,311.911Z"
              transform="translate(240.612 0.841)"
              fill="#fff"
            ></path>
            <path
              id="Path_18"
              data-name="Path 18"
              d="M627.786,279.65c0,6.9-4.87,11.564-12.174,11.564h-28v96.579c0,7.3-4.667,12.782-12.172,12.782-7.306,0-12.176-5.478-12.176-12.782V291.214h-28c-7.1,0-12.172-4.667-12.172-11.564s5.072-11.564,12.172-11.564h80.346C622.916,268.086,627.786,272.753,627.786,279.65Z"
              transform="translate(297.691 0.841)"
              fill="#fff"
            ></path>
            <path
              id="Path_19"
              data-name="Path 19"
              d="M677.81,400.6c-6.7,2.841-13.392-.608-16.03-7.508l-6.491-17.449H602.129L595.636,393.1c-2.637,6.9-9.128,10.348-16.028,7.508s-9.333-9.738-6.7-16.434l44.028-108.346c2.233-5.682,6.7-8.52,11.768-8.52a12.2,12.2,0,0,1,11.77,8.52l44.028,108.346C687.144,390.867,684.708,397.764,677.81,400.6Zm-30.435-46.462-18.667-50.318-18.665,50.318Z"
              transform="translate(350.131)"
              fill="#fff"
            ></path>
            <path
              id="Path_20"
              data-name="Path 20"
              d="M660.572,280.868V387.793c0,7.3-4.868,12.782-12.172,12.782-7.507,0-12.174-5.478-12.174-12.782V280.868c0-7.3,4.667-12.782,12.174-12.782C655.7,268.086,660.572,273.564,660.572,280.868Z"
              transform="translate(419.312 0.841)"
              fill="#fff"
            ></path>
            <path
              id="Path_21"
              data-name="Path 21"
              d="M770.112,280.868V387.793c0,7.3-4.667,12.782-12.174,12.782a11.965,11.965,0,0,1-9.537-4.057l-62.9-81.971v73.245c0,7.3-4.87,12.782-12.172,12.782-7.508,0-12.174-5.478-12.174-12.782V280.868c0-7.3,4.667-12.782,12.174-12.782,4.665,0,7.505,2.231,10.549,5.883l61.882,80.956V280.868c0-7.3,4.87-12.782,12.174-12.782C765.446,268.086,770.112,273.564,770.112,280.868Z"
              transform="translate(446.116 0.841)"
              fill="#fff"
            ></path>
            <path
              id="Path_22"
              data-name="Path 22"
              d="M877.546,389.772c0,6.9-4.87,11.564-12.174,11.564H805.315c-7.507,0-12.174-5.478-12.174-12.782V281.629c0-7.3,4.667-12.782,12.174-12.782h59.041c7.3,0,12.174,4.667,12.174,11.564,0,6.7-4.87,11.363-12.174,11.363h-47.68v30.231h38.349c7.1,0,12.375,4.667,12.375,11.566,0,7.1-5.277,11.766-12.375,11.766H816.676v33.072h48.7C872.675,378.409,877.546,383.076,877.546,389.772Z"
              transform="translate(588.004 1.659)"
              fill="#fff"
            ></path>
          </g>
        </svg>
      </div>
    </React.Fragment>
  );
};

export default Logo;
