const emptyState = {
  modal: 0,
  logged: false,
  skip: false,
  register: false,
  loading: true,
  Visible: false,
  searchVisible: false,
  currentTap: "Discover",
  formIndicator: 0,
  requestState: false,
  searchValue: "",
  searchResult: {},
  user: {
    id: 0,
    first_name: "",
    last_name: "",
    email: "",
    currency: "",
    email_verified_at: null,
    created_at: "",
    updated_at: "",
    phone_number: null,
    status: "",
    type: "",
    is_active: 0,
    image: null,
  },
  editUser: {
    id: 0,
    first_name: "",
    last_name: "",
    email: "",
    currency: "",
    email_verified_at: null,
    created_at: "",
    updated_at: "",
    phone_number: null,
    status: "",
    type: "",
    is_active: 0,
    image: null,
    secure: true,
  },
};
export default emptyState;
