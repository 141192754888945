import React, { useState } from "react";
import music from "../../../assets/channels4.jpg";

function ArtistCard({ artist }) {
  console.log(artist);
  const [api] = useState(process.env.REACT_APP_IMAGE_URI);
  const api2 = process.env.REACT_APP_IMAGE_URI2;

  return (
    <>
      <div>
        <div className="row">
          <div>
            {artist.image ? (
              <img
                alt=""
                src={
                  artist?.image?.includes("storage")
                    ? `${api2 + artist?.image}`
                    : `${api + artist?.image}`
                }
                className="history-artist-card-image"
              />
            ) : (
              <img alt="" src={music} className="history-artist-card-image" />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default ArtistCard;
