import React,{useState} from 'react'
import music from "../../../assets/channels4.jpg";

function Artist_popup({artist}) {
  console.log(artist)
  const [api] = useState(process.env.REACT_APP_IMAGE_URI);
  const [api2] = useState(process.env.REACT_APP_IMAGE_URI2);

  return (
      <>
      
    <div  className='popup-artist-card' style={{margin:'0 10px'}}>
        <div className='row'>
          <div className='col ' style={{maxWidth:'77%'}}>
                {artist.image ?
                (
                  <img
                    alt=""        
                    src={artist.image?.includes("storage")?`${api2 + artist.image}`:`${api + artist.image}`}
                    className="popup-artist-card-image"
                  />
                  ) : (
                    <img alt="" src={music} className="popup-artist-card-image" />
                  )}
          </div>
          <div className='col popup-artist-card-name' >
            <span>
                {artist?.name}
            </span>
            <div className='popup-artist-card-desc font-noto'>
            {artist?.category?.name}
            </div>
          </div>
        </div>
    </div>  
    </>
  )
}

export default Artist_popup