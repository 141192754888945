import React from "react";
const AboutUs = () => {
  return (
    <div className="container">
      <div className="row w-100 justify-content-center">
        <section id="about-us">
          <h1 className="font-noto aboutus-title">Our History</h1>
          <p className="text-muted ml-4" style={{ fontWeight: "400" }}>
            Entertainme focuses on customizing the ideal event and entertainment
            solution services for their clients. We are a dedicated service
            filled with decades of industry insights and international
            partnerships. Our team is assembled with individuals who hold
            extensive expertise in all aspects of the entertainment business. We
            deliver special event production, talent booking, marketing, social
            initiatives, programming, vendor acquisition and corporate
            sponsorship. EntertainMe is a talent booking service and is
            manufactured as an e-world that is both convenient for event
            planning, and also helpful to any individual - who like us – loves
            entertainment.
          </p>
        </section>
      </div>
    </div>
  );
};

export default AboutUs;
