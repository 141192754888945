import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import loginImg from "../../assets/login_image.jpg";
import { UserActions } from "../../store/User";
import { login } from "../../httpService/user";
import FacebookLogin from "react-facebook-login";
import { GoogleLogin } from "react-google-login";
import axios from "axios";
const LogIn = () => {
  const [user, setUser] = useState({ email: "", password: "" });
  const dispatch = useDispatch();
  const { email, password, error } = useSelector((state) => state.User.login);
  const onChangeInput = (e) => {
    e.preventDefault();
    const key = e.target.name;
    const value = e.target.value;
    setUser({ ...user, [key]: value });
  };
  const responseGoogle = (response) => {
    console.log(response);
  };
  const onHandleSubmit = async (e) => {
    e.preventDefault();
    const result = await login(user.email, user.password);
    if (result.error)
      return dispatch(
        UserActions.onChangeLogin([
          { element: "error", value: result.error.message },
        ])
      );
    dispatch(
      UserActions.onChangeLogin([
        { element: "name", value: result.data.name },
        { element: "email", value: user.email },
        { element: "password", value: user.password },
      ])
    );

    window.location.href = "/home";
  };
  const onGoogleLogin = async (callBack) => {
    console.log(callBack);
    const result = await axios.post(
      "http://165.227.140.163/entertainme/public/api/login/google",
      {
        token: callBack?.accessToken,
        type: "web",
        user: {
          user: {
            givenName: callBack?.Ru?.AY,
            familyName: callBack?.Ru?.QW,
            email: callBack.Ru?.Iv,
          },
        },
      }
    );
    console.log(result);
  };
  const onFacebookLogin = async (callBack) => {
    console.log(callBack);
    let name = callBack?.name?.split(" ");
    let givenname = name[0];
    let famname = name[1];
    const response = await axios.post(
      "https://admin.entertainme.co/api/login/facebook",
      {
        token: callBack?.accessToken,
        user: {
          givenName: givenname,
          familyName: famname,
          email: callBack?.email,
        },
      }
    );
    console.log(response);
    if (response.status === 200) {
      localStorage.setItem("x-auth-token", response?.data?.data?.token);
      localStorage.setItem("user", response.data.data.name);
      window.location.href = "/home";

      return { data: response.data.data, error: null };
    } else {
      return {
        data: null,
        error: { key: "invalid", message: response.response.data.message },
      };
    }
  };
  return (
    <>
      <meta
        name="google-signin-client_id"
        content="12345678-gbgin9h7q69rpjehq1cd2441b4nosnid.apps.googleusercontent.com"
      ></meta>
      <main onSubmit={(e) => e.preventDefault()}>
        <span>
          <div id="login-page-container">
            <div className="p-0 h-100 container-fluid">
              <div className="row h-100 no-gutters">
                <div className="login-background-container col-lg-6">
                  <div className="login-background">
                    <img
                      alt="login"
                      src={loginImg}
                      className="login-bg img-fluid w-100"
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="login-form d-flex align-items-center justify-content-center h-100 flex-column">
                    <div className="page-title h1 font-noto">LOGIN</div>
                    <form className="w-75 pt-sm-3 mt-sm-3">
                      <div className="form-group ">
                        <input
                          id="email"
                          type="email"
                          placeholder="Email address.."
                          name="email"
                          onChange={(e) => onChangeInput(e)}
                          required="required"
                          autoComplete="email"
                          autoFocus="autofocus"
                          className="form-control bg-dark border-0 font-noto-m rounded-0 text-muted "
                        />
                      </div>
                      <div className="form-group">
                        <input
                          id="password"
                          placeholder="Password.."
                          type="password"
                          name="password"
                          onChange={(e) => onChangeInput(e)}
                          required="required"
                          autoComplete="current-password"
                          className="form-control bg-dark border-0 font-noto-m form-control rounded-0 text-muted "
                        />
                      </div>
                      <div className="form-group">
                        <p style={{ color: "gray" }}>
                          {error.length > 0 &&
                            "the email or password are incorrect"}
                        </p>
                      </div>
                      <div className="form-group d-flex justify-content-between align-items-center">
                        <div className="form-check">
                          <div className="text-normal custom-control custom-checkbox">
                            <input
                              // variant="primary"
                              id="remember"
                              type="checkbox"
                              name="remember"
                              autoComplete="off"
                              className="custom-control-input outlineNone"
                              value="true"
                            />
                            <label
                              htmlFor="remember"
                              className="custom-control-label"
                              style={{ color: "#f70" }}
                            >
                              Remember Me
                            </label>
                          </div>
                        </div>
                        <Link
                          to="/forgetPassword"
                          className="btn btn-link text-normal"
                        >
                          Forgot Your Password?
                        </Link>
                      </div>

                      <div
                        className="form-group mb-0 "
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      >
                        <button
                          type="submit"
                          onClick={(e) => onHandleSubmit(e)}
                          className="btn mr-3 font-noto px-5 btn-normal rounded-0"
                        >
                          Sign in
                        </button>
                        <div
                          className="btn mr-3 font-noto  rounded-0"
                          style={{
                            margin: "10px 0",
                            backgroundColor: "#0a51f8",
                          }}
                        >
                          <FacebookLogin
                            //appId="781524736166854"
                            appId="313249600652011"
                            fields="name,email,picture"
                            callback={onFacebookLogin}
                            cssClass="bt facebook font-noto border-0 btn-secondary btn-block rounded-0"
                          />
                        </div>
                        <div className="btn mr-3 font-noto  rounded-0">
                          <GoogleLogin
                            clientId={process.env.REACT_APP_GOOGLE_ID}
                            onSuccess={onGoogleLogin}
                            onFailure={responseGoogle}
                            cookiePolicy="single_host_origin"
                            buttonText="Login with Google"
                            className="btn gmail font-noto border-0 btn-secondary justify-content-center btn-block rounded-0"
                          />
                        </div>
                        <button
                          type="button"
                          onClick={() => (window.location.href = "/register")}
                          className="btn mr-3 font-noto btn-outline-normal rounded-0"
                        >
                          New Member Registration
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </span>
      </main>
    </>
  );
};

export default LogIn;
