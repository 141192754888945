import React, { useEffect, useState } from "react";
import Logo from "../logo.jsx";
import { Link } from "react-router-dom";
import { authed, logout } from "../../../httpService/user";
import { useDispatch, useSelector } from "react-redux";
import { UiActions } from "../../../store/Ui/index.js";
import { search } from "../../../httpService/artists.js";
import { useLocation } from "react-router-dom";
import "./navbar.css";
const Navbar = () => {
  const [api] = useState(process.env.REACT_APP_IMAGE_URI);
  const api2 = process.env.REACT_APP_IMAGE_URI2;
  const location = useLocation();
  const { FilterSelectedArtists } = useSelector((state) => state.Artists);
  const cart = localStorage.getItem("Cartitems");
  const [searchValue, setSearchValue] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  const [hideSearchbar, setHideSearch] = useState(false);
  const [Focus, setFocus] = useState(false);
  const dispatch = useDispatch();
  const name = localStorage.getItem("user");
  const onHandleLogout = () => {
    const result = logout();
    if (result) window.location.href = "/home";
  };
  const onHandleSearch = async (text) => {
    setSearchValue(text);
    if (text.length % 2 === 0) {
      let result = await search(text);
      if (result) {
        setSearchResult(result.artists);
        setFocus(true);
      }
    }
  };
  const setModal = () => {
    dispatch(
      UiActions.onChangeModel([{ element: "modalName", value: "cart" }])
    );
  };
  const onOpenArtist = (item) => {
    window.location.href = `/artist/${item?.id}/${item?.name}`;
  };
  useEffect(() => {
    console.log(location.pathname);
    console.log(location.pathname, "index");
    if (location.pathname.indexOf("categoryDetails") != -1) {
      setHideSearch(true);
    } else {
      setHideSearch(false);
    }
  }, [window.location.pathname]);
  console.log(searchResult);
  return (
    <nav className="navbar navbar-dark bg-faded navbar-expand-lg">
      <div className="container-fluid">
        <a
          href="/"
          className="navbar-brand router-link-exact-active router-link-active"
          target="_self"
        >
          <Logo width="200" height="22" />
        </a>
        <button
          type="button"
          aria-label="Toggle navigation"
          aria-controls="navcollapse"
          aria-expanded="false"
          className="navbar-toggler"
          data-target="#navcollapse"
          data-toggle="collapse"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          id="navcollapse"
          className="collapse navbar-collapse justify-content-end"
        >
          <ul className="navbar-nav ml-auto align-items-center">
            <li className="navbar-text p-0 pr-2">
              <div data-v-36959b50="" className="global-search">
                {!hideSearchbar && (
                  <div
                    data-v-36959b50=""
                    className="search-input-container"
                    onFocus={() => setFocus(true)}
                  >
                    <input
                      data-v-36959b50=""
                      type="text"
                      autoComplete="off"
                      className="border-0 form-control"
                      id="__BVID__12"
                      value={searchValue}
                      onChange={(e) => onHandleSearch(e.target.value)}
                    />
                    <svg
                      data-v-36959b50=""
                      width="1em"
                      height="1em"
                      viewBox="0 0 20 20"
                      focusable="false"
                      role="img"
                      alt="icon"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      className="bi-search search-icon b-icon bi text-light"
                      style={{ fontSize: "150%" }}
                    >
                      <g data-v-36959b50="">
                        <path
                          fillRule="evenodd"
                          d="M12.442 12.442a1 1 0 011.415 0l3.85 3.85a1 1 0 01-1.414 1.415l-3.85-3.85a1 1 0 010-1.415z"
                          clipRule="evenodd"
                        ></path>
                        <path
                          fillRule="evenodd"
                          d="M8.5 14a5.5 5.5 0 100-11 5.5 5.5 0 000 11zM15 8.5a6.5 6.5 0 11-13 0 6.5 6.5 0 0113 0z"
                          clipRule="evenodd"
                        ></path>
                      </g>
                    </svg>
                    <span
                      data-v-36959b50=""
                      aria-hidden="true"
                      className="search-icon spinner-border spinner-border-sm text-light"
                      style={{ display: "none" }}
                    ></span>
                  </div>
                )}
                {Focus && (
                  <div data-v-36959b50="" className="results-container">
                    {searchValue.length > 0 &&
                    searchResult &&
                    searchResult.length > 0 ? (
                      <>
                        <div
                          className="search-results"
                          onBlur={() => setFocus(false)}
                          onMouseLeave={() => setFocus(false)}
                        >
                          {searchResult?.map((item, index) => (
                            <div onClick={() => onOpenArtist(item)} key={index}>
                              <div className="result-artist border-bottom-primary router-link-exact-active router-link-active">
                                <h5 className="m-0">
                                  <img
                                    style={{
                                      width: "50px",
                                      borderRadius: "2px",
                                    }}
                                    src={
                                      item.image?.includes("storage")
                                        ? `${api2 + item.image}`
                                        : `${api + item.image}`
                                    }
                                  ></img>
                                  <span style={{ marginLeft: "10px" }}>
                                    {item?.name}
                                  </span>
                                </h5>
                              </div>
                            </div>
                          ))}
                        </div>
                      </>
                    ) : (
                      <div
                        data-v-36959b50=""
                        className="search-tip"
                        onBlur={() => setFocus(false)}
                        onMouseLeave={() => setFocus(false)}
                      >
                        Search by name or category (ie: solo, violin, acoustic,
                        band, etc..)
                      </div>
                    )}
                  </div>
                )}
              </div>
            </li>
            {authed() ? (
              <li className="nav-item dropdown">
                <Link
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  to="#"
                  className="nav-link dropdown-toggle"
                  id="profile"
                >
                  {name}
                </Link>
                <ul className="dropdown-menu" aria-labelledby="profile">
                  <li role="presentation">
                    <Link
                      to="/history"
                      role="menuitem"
                      href="/history"
                      className="dropdown-item"
                    >
                      Profile
                    </Link>
                  </li>
                  <li role="presentation">
                    <Link
                      to="/wishlist"
                      role="menuitem"
                      href="/wishlist"
                      className="dropdown-item"
                    >
                      Wishlist
                    </Link>
                  </li>
                  <li role="presentation">
                    <Link
                      to="#"
                      role="menuitem"
                      href="https://entertainme.co/logout"
                      className="dropdown-item"
                      onClick={() => onHandleLogout()}
                    >
                      Logout
                    </Link>
                  </li>
                </ul>
              </li>
            ) : (
              <li className="nav-item">
                <Link target="_self" to="/LogIn" className="nav-link">
                  Login
                </Link>
              </li>
            )}
            <li className="nav-item">
              <Link to="/categories" className="nav-link" target="_self">
                Categories
              </Link>
            </li>
            {/* <li className="nav-item">
              <Link to="/updates" className="nav-link" target="_self">
                Updates
              </Link>
            </li> */}
            <li className="nav-item">
              <Link to="/about-us" className="nav-link" target="_self">
                About Us
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/contactUs" className="nav-link" target="_self">
                Contact Us
              </Link>
            </li>
            <li
              data-count={FilterSelectedArtists.length}
              className="nav-item cart-icon"
              data-toggle="modal"
              data-target="#cart-modal"
              onClick={() => setModal()}
            >
              <Link target="_self" to="" className="nav-link">
                <svg
                  aria-hidden="true"
                  width="18"
                  height="30"
                  viewBox="0 0 520 512"
                  focusable="false"
                  className="fa-icon"
                >
                  <g>
                    <path d="M384 112v352c0 26.51-21.49 48-48 48H48c-26.51 0-48-21.49-48-48V112c0-26.51 21.49-48 48-48h80c0-35.29 28.71-64 64-64s64 28.71 64 64h80c26.51 0 48 21.49 48 48zM192 40c-13.255 0-24 10.745-24 24s10.745 24 24 24 24-10.745 24-24-10.745-24-24-24m96 114v-20a6 6 0 0 0-6-6H102a6 6 0 0 0-6 6v20a6 6 0 0 0 6 6h180a6 6 0 0 0 6-6z"></path>
                  </g>
                </svg>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
export default Navbar;
