import React, { useState } from 'react'
import { useDispatch, useSelector } from "react-redux";

function Events_card({event ,checkedevents, setCheckedEvents,artistid,setEventInfo,EventInfo}) {
  console.log(artistid)
const { cartArtists, cart, carts } = useSelector((state) => state.Events);
const [ischecked,setIschecked]=useState(false)
  const handlecheck=()=>{
    let ids=[...checkedevents]
    let ind=ids.indexOf(event.id)
    if(ind!==-1)
    ids.splice(ind,1)
    else
    ids.push(event.id)
    let artistsid=[...event.artists]

    for(let i=0;i<artistid.length;i++)
    {
      console.log(artistsid)
      console.log(artistid[i].id)
    let inda= artistsid.indexOf(artistid[i].id)
    if(inda!==-1)
    {

    }
    else
    artistsid.push(artistid[i].id)
    }
    console.log(artistsid)
    setCheckedEvents(ids)
    let data = {
      additional_info:event.additional_info,
      budget:event.budget,
      duration:event.duration,
      address:event.address,
      date:event.date,
      description:event.description,
      placement:event.placement,
      guests:event.guests,
      additional_equipment:event.additional_equipment,
      artists:artistsid,
      name:event.name,
      email:event.contact_email,
      phone_number:event.contact_phone,
      production_items:event.items_of_production,
      type:event.type,
      budget_tbd:event.budget_tbd,
      duration_tbd:event.duration_tbd,
      event_name:event.event_name
    
    };
    //console.log(data)
    let orderinfo=[...EventInfo]
    orderinfo.push(data)
    setEventInfo(orderinfo)
  }
  return (
    <div>
    <label className="orange-checkbox-container">
      <span style={{position:'relative',top:'-5px',margin:'0 5px'}}>{event.event_name}</span>
    <input type="checkbox"
      //checked={checkedevents.includes(event.id)}
      onClick={handlecheck}
     />
    <span className="checkmark"></span>
  </label>
  </div>
  )
}

export default Events_card