import { CategoriesActions } from ".";
import { getCategories, getCategory ,getCategory2} from "../../httpService/categories";
import { UiActions } from "../Ui";
import { onGetArtists } from "../Artists/actions";
export const onGetCategories = () => {
  return async (dispatch) => {
    dispatch(UiActions.onHandleUiChange([{ element: "loading", value: true }]));
    // get categories
    let categoriesResult = await getCategories();
    dispatch(CategoriesActions.onChangeCategories({ data: categoriesResult }));
    //set selected categories for discover screen
    dispatch(onGetArtists());
    dispatch(
      UiActions.onHandleUiChange([{ element: "loading", value: false }])
    );
  };
};

export const onLoadArtists = (id,mainstream,international, page,search) => {
  return async (dispatch) => {
    let cat = await getCategory2(id,mainstream,international, page,search);
    console.log(cat)
    if (cat) {
      dispatch(CategoriesActions.onChangeCategory({ data: cat }));
    //console.log(cat.artists.data)
      if(search)
      {
      dispatch(CategoriesActions.onLoadData2(cat?.artists.data));
      }
      else{
       
        dispatch(CategoriesActions.onLoadData(cat?.artists.data)); 
      }
    }
  };
};
export const onLoadCategory = (id,mainstream,international,page,search) => {
  return async (dispatch) => {
    let result = await getCategory2(id,mainstream,international,page,search);
    console.log(result)
    if (result) {
      dispatch(
        CategoriesActions.onChangeCategory({
          data: result,
        })
      );
      dispatch(
        CategoriesActions.onChangeItem({
          element: "selectedArtists",
          data: result.artists.data,
        })
      );
    }
  };
};

export const onFilterArtists = (selectedArtists, filter) => {
  return (dispatch) => {
    let artists = [...selectedArtists];
    let filtered = artists.filter((item) => {
      if (
        item.international === filter.international &&
        item.mainstream === filter.mainstream
      )
        return item;
    });
    dispatch(CategoriesActions.onSetFilter(filtered));
  };
};
