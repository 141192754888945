import axios from "axios";
import React, { useEffect, useState } from "react";
import { removeEvents } from "../../../httpService/event";
import ArtistCard from "./ArtistCard";
import { Swiper, SwiperSlide } from "swiper/react";
import { getArtist } from "../../../httpService/artists";
import pin from "../../../assets/pin.png";
const Detailspopup = ({ event }) => {
  function dateformate(date) {
    let d = date.split("T", 1);
    return d[0];
  }
  console.log(event, "event");
  const [artists, setArtists] = useState([]);
  useEffect(() => {
    async function getartists() {
      let a = [];

      await event?.artists.map(async (art) => {
        const res = await getArtist(art);

        a.push(res);

        setArtists(artists.concat(a));
      });
    }
    getartists();
  }, []);
  return (
    <div
      id={`cart-modal${event.id}`}
      role="dialog"
      aria-labelledby="cart-modal"
      aria-describedby="cart-modal"
      className="modal fade"
      aria-hidden="true"
      aria-modal="true"
      tabIndex="-1"
    >
      <div className="modal-dialog modal-lg modal-dialog-centered">
        <span tabIndex="0"></span>
        <div
          role="document"
          id="cart-modal"
          tabIndex="-1"
          className="modal-content bg-transparent border-0"
        >
          <div
            id="cart-modal"
            className="modal-body book-modal-body border-normal"
            style={{ padding: "0px" }}
          >
            <div className="additional-services my-3">
              <div>
                <p
                  className="Bookingpopup-titleone"
                  style={{ marginBottom: "33px" }}
                >
                  Event Details
                </p>{" "}
              </div>
              {event ? (
                <div style={{ display: "block", flexDirection: "row" }}>
                  <span style={{ color: "#DFDFDF", fontSize: "16px" }}>
                    {event?.description}
                  </span>
                  <div>
                    <span style={{ color: "#FF671D", fontSize: "14px" }}>
                      Featured Artists:
                    </span>
                  </div>
                  <Swiper
                    spaceBetween={2}
                    slidesPerView={"auto"}
                    style={{ marginTop: "19px" }}
                  >
                    <div className="swiper-wrapper">
                      {artists?.map((art, index) => {
                        return (
                          <span
                            style={{
                              font: "12px",
                              color: "white",
                              margin: "0 4px",
                            }}
                            key={index}
                          >
                            {art.name}
                          </span>
                        );
                      })}
                    </div>
                  </Swiper>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      color: "white",
                      fontSize: "14px",
                      marginTop: "20px",
                    }}
                  >
                    <div>
                      <img
                        src={pin}
                        style={{ height: "20px", width: "20px" }}
                      ></img>
                      <span style={{ margin: "0 5px" }}>{event?.address}</span>
                    </div>
                    <div>
                      Date:{event.date ? dateformate(event.date) : "TBD"}
                    </div>
                  </div>
                </div>
              ) : (
                <div style={{ color: "red" }}>No Details</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Detailspopup;
