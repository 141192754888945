import http from "./httpHandler";
import _try from "../utils/try";
import handleServerError from "./handleServerError";
const route = process.env.REACT_APP_API + "/api/";

export const requestOrder = _try(async (data) => {
  console.log(data);
  let headers, token;
  headers = {};
  token = localStorage.getItem("x-auth-token");
  if (token) headers = { Authorization: `Bearer ${token}` };
  const response = await http.post(route + "order", data, { headers: headers });

  const result = handleServerError(response);
  if (result) return { error: result, data: null };
  if (response.data) return { error: null, data: response.data };
  return { data: response.data, error: null };
});

export const updateOrder = _try(async (id, data) => {
  let token, headers;
  token = localStorage.getItem("x-auth-token");
  if (token) headers = { Authorization: `Bearer ${token}` };
  //console.log(route)
  let response = await http.put(route + `order/${id}`, data, {
    headers: headers,
  });
  //console.log(response)
  if (response.response) {
    return {
      data: null,
      error: response.response.data.errors[Object.keys[0]][0],
    };
  }
  return { data: response.data, error: null };
});
export const getEventsByUser = async () => {
  const token = localStorage.getItem("x-auth-token");
  let response = await http.get(route + "order", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  console.log(token);
  if (response.status === 200) return { data: response.data, error: null };
  else return { data: null, error: response.response.data };
};
export const getUserData = async (type) => {
  const token = localStorage.getItem("x-auth-token");
  let response = await http.get(route + "user", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      type: type,
    },
  });
  console.log(token);
  if (response.status === 200) return { data: response.data, error: null };
  else return { data: null, error: response.response.data };
};
export const removeEvents = async (eventid) => {
  let headers;
  let token = localStorage.getItem("x-auth-token");
  if (token) headers = { Authorization: `Bearer ${token}` };
  const response = await http.delete(route + "order/" + eventid, {
    headers: headers,
    data: { id: eventid },
  });

  const result = handleServerError(response);
  if (result) return { data: null, error: result };
  else {
    if (response?.response?.status === 401)
      return { data: null, error: response.response.data.message };
    else return { data: response.data, error: null };
  }
};
