import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Artist_popup from "./Artist-popup";
import Events_card from "./Events_card";
import { getEventsByUser,updateOrder } from "../../../httpService/event";
import { UiActions } from "../../../store/Ui/index.js";


const Bookingpopup = () => {
  
  const { searchArtists, FilterSelectedArtists } = useSelector(
    (state) => state.Artists
  );
  const dispatch = useDispatch();
  const [events,setEvents]=useState([])
  const [checkedevents,setCheckedEvents]=useState([])
  const [EventInfo,setEventInfo]=useState([])
    const [error,setError]=useState('')
    async function getevents(){
        const result = await getEventsByUser();
        if (result.error)
        {
            setError(result.error)
        }
        else
        {   
            setEvents(result.data)

        }
    }
    const setModal = () => {
      dispatch(
        UiActions.onChangeModel([{ element: "modalName", value: "cart" }])
      );
    };
    async function handlesubmit(){
      for(let i=0;i<checkedevents.length;i++)
      {
       //console.log(checkedevents[i])
       //console.log(checkedevents.length)
       //console.log(EventInfo[i])

        const result = await updateOrder(checkedevents[i], EventInfo[i]);
        //console.log(result)
        if(result.data)
        {
          window.location.href = "/home";
          
        }
        else{
         
        }
      }
    }
    useEffect(()=>{
      getevents()

    },[])
    //console.log(events)
    //console.log(EventInfo)
    console.log(FilterSelectedArtists)
  return (
    <div
      id="cart-modal"
      role="dialog"
      aria-labelledby="cart-modal"
      aria-describedby="cart-modal"
      className="modal fade"
      aria-hidden="true"
      aria-modal="true"
      tabIndex="-1"
    >
      <div className="modal-dialog modal-lg modal-dialog-centered">
        <span tabIndex="0"></span>
        <div
          role="document"
          id="cart-modal"
          tabIndex="-1"
          className="modal-content bg-transparent border-0"
        >
          <div
            id="cart-modal"
            className="modal-body book-modal-body border-normal"
          >
          <div className="additional-services my-3" >
            <div>
              <p className="Bookingpopup-titleone">Booking An Event</p>{" "}
            </div>
            <div style={{display:'flex'}}>
            {FilterSelectedArtists.map((art,index)=>{
              return(
                <Artist_popup key={index} artist={art}></Artist_popup>
              )
            })
            }
            </div>
            <div>
              <p className="Bookingpopup-subtitle">Recent Events</p>{" "}
            </div>
            {events.length!=0 &&
              <div>
                {events.map((event,index)=>{ return(
                  <>
                {index!=events.length-1 &&<>
                  <Events_card key={index} event={event} setCheckedEvents={setCheckedEvents}
                  artistid={FilterSelectedArtists} 
                  setEventInfo={setEventInfo}
                  checkedevents={checkedevents}
                  EventInfo={EventInfo}
                  ></Events_card>
                  <hr className="new2"></hr>
                  </>
                }
                {index==events.length-1 &&<>
                  <Events_card key={index} event={event}
                   checkedevents={checkedevents}
                    setCheckedEvents={setCheckedEvents}
                     artistid={FilterSelectedArtists}
                     setEventInfo={setEventInfo}
                     EventInfo={EventInfo}
                     ></Events_card> 
                  </>
                }
                </>
                )})
                }
              </div>
              
            }
            <div
                data-toggle="modal"
                data-target="#cart-modal"
                style={{cursor:'pointer'}}
                onClick={() => setModal()}
            >
              <label className="booking-pop-up-add">
                <span className="booking-pop-up-add-symbol">+</span>
                </label>
              <span className="booking-pop-up-add-text">Add New Project</span>
            </div>
            <div className="flex-center">
                <button className="booking-pop-up-btn" onClick={handlesubmit}>
                   ADD TO BOOKING
                </button>
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Bookingpopup;
