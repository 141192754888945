import http from "./httpHandler";
import _try from "../utils/try";
const route = process.env.REACT_APP_API + "/api/";

export const getCategories = _try(async () => {
  const response = await http.get(route + "categories");
  if (response.status === 200) {
    return response.data;
  } else {
  }
});

export const getCategory = _try(async (id, page) => {
  const response = await http.get(route + "categories/" + id + "?page=" + page);
  if (response.status === 200) {
    return response.data;
  } else return null;
});
export const getCategory2 = _try(async (id, mainstream,international,page,search) => {
  const response = await http.get(route + "categories/" + id + "?mainstream=" + mainstream +"&international="+international+"&page="+page+"&search="+search);
  console.log('cat',response)
  if (response.status === 200) {
    return response.data;
  } else return null;
});

export const getArtist = _try(async (id) => {
  const response = await http.get(route + "artists/" + id);
  if (response.status === 200) {
    return response.data;
  } else {
  }
});
