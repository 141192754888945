import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCategories as httpGetCategories } from "../../httpService/categories";
import { CategoriesActions } from "../../store/Categories";
import { Link } from "react-router-dom";
//import Carousel from "react-elastic-carousel";
import Empty from "../UIs/Empty";
import { Carousel } from "3d-react-carousal";
import Item from "./Item.js";
const api = process.env.REACT_APP_IMAGE_URI;
const api2 = process.env.REACT_APP_IMAGE_URI2;
// const breakPoints = [
//   { itemsToShow: 1 },
//   { itemsToShow: 2, itemsToScroll: 2 },
//   { itemsToShow: 3 },
//   { itemsToShow: 4 },
// ];

const Categories = () => {
  const api = process.env.REACT_APP_IMAGE_URI;
  const dispatch = useDispatch();
  const carouselRef = useRef();
  const [slides,setSlide]=useState([]);
  const categories = useSelector((state) => state.Categories.categories);
  // const breakPoints = [
  //   { width: 5, itemsToShow: 1 },
  //   { width: 5, itemsToShow: 2, itemsToScroll: 2 },
  //   { width: 5, itemsToShow: 3 },
  //   { width: 5, itemsToShow: 4 },
  // ];
  const onNextStart = (currentItem, nextItem) => {
    if (currentItem.index === nextItem.index) {
      // we hit the last item, go to first item
      carouselRef.current.goTo(0);
    }
  };

  const onPrevStart = (currentItem, nextItem) => {
    console.log(currentItem.index, nextItem.index);

    if (currentItem.index === nextItem.index) {
      // we hit the first item, go to last item
      carouselRef.current.goTo(categories.length);
    }
  };
  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 5},
    { width: 1200, itemsToShow: 5},
    { width: 1800, itemsToShow: 5 },
    { width: 1900, itemsToShow: 5 },
    { width: 3000, itemsToShow: 1 },
  ];
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);
  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }
  useEffect(() => {
    async function fetch() {
      dispatch(
        CategoriesActions.onChangeCategories({
          data: await httpGetCategories(),
        })
      );
    }
    fetch();
  }, [dispatch]);

   
  useEffect(()=>{
    let c=[]
    for(let i =0;i<categories?.length;i++)
    {
         c.push(
           <div style={{ width: "50%" }}>
             <Link
               className="category"
               to={`/categoryDetails/${categories[i].id}/${categories[i].name}`}
             >
               <img
                 key={i}
                 src={
                   categories[i].image?.includes("storage")
                     ? `${api2}${categories[i].image}`
                     : `${api}${categories[i].image}`
                 }
                 style={{
                   objectFit: "cover",
                   height: "312.5px",
                   width: "100%",
                 }}
               ></img>
               <div
                 className="category-title"
                 style={{
                   position: "absolute",
                   bottom: "0",
                   left: "50%",
                   transform: "translate(-50%, -22%)",
                 }}
               >
                 {categories[i].name}
               </div>
             </Link>
           </div>
         );
    }
 

   setSlide(c)
  },[categories])

const callback = function(index){
    console.log("callback",index);
}
  return (
    <main>
      <div className="b-overlay-wrap position-relative">
        <div className="categories-page w-100">
          {categories && categories.length > 0 ? (
            <React.Fragment>
              <div>
                <h1 className="categories-title">Categories</h1>
              </div>
              <div className="categories-container">
                {slides.length>0 &&
                  <Carousel
                  slides={slides}
                  autoplay={true}
                  interval={7000}
                  onSlideChange={callback}
                />
                }
                
                {/* <Carousel
                  disableArrowsOnEnd={true}
                  breakPoints={breakPoints}
                  //itemsToShow={6}
                  onResize={(currentBreakPoint) =>
                    console.log(currentBreakPoint)
                  }
                  width={windowSize.innerWidth}
                  ref={carouselRef}
                  onPrevStart={onPrevStart}
                  onNextStart={onNextStart}
                >
                  {categories.map((item, index) => {
                    return (
                      // width: "195px", height: "312.5px"
                      <div style={{ width: "100%", height: "312.5px" }}>
                        <Link
                          key={index}
                          to={`/categoryDetails/${item.id}/${item.name}`}
                          className="category"
                          style={{
                            backgroundImage: item.image?.includes("storage")
                              ? `url(${api2}${item.image})`
                              : `url(${api}${item.image})`,
                            width: "100%",
                            objectFit: "cover",
                            height: "312.5px",
                            display: "block",
                            position: "relative",
                          }}
                        >
                          <div
                            className="category-title"
                            style={{
                              position: "absolute",
                              bottom: "0",
                              left: "50%",
                              transform: "translate(-50%, -22%)",
                            }}
                          >
                            {item.name}
                          </div>
                        </Link>
                      </div>
                    );
                  })}
                </Carousel> */}
              </div>
            </React.Fragment>
          ) : (
            <Empty />
          )}
        </div>
      </div>
    </main>
  );
};

export default Categories;
