import React, { useEffect, useState } from 'react'
import {useDispatch}from 'react-redux'
import {getArtist} from '../../httpService/artists'
import {getFavourites} from '../../httpService/favourites'
import { Link } from "react-router-dom";
import _ from "lodash";
import music from "../../assets/channels4.jpg";
import { useSelector } from "react-redux";
import {onGetUserFav} from '../../store/Ui/actions'
function Wishlist() {
    const favs = useSelector((state) => state.Favorites);
    const dispatch=useDispatch()
    const [fav,setFav]=useState([])
    let newA = [];
    const [error,setError]=useState('')
    const [api] = useState(process.env.REACT_APP_IMAGE_URI);
    const api2 = process.env.REACT_APP_IMAGE_URI2;
    const { favourites } = useSelector((state) => state.Favorites);
    console.log(favs,'favs')
    useEffect(()=>{
     
        dispatch(onGetUserFav())
  
    },[])
    

  return (
    <>
        <div>
       <div className="category-name d-flex text-white" style={{margin:"20px 50px"}}>
                          <span  
                            className="m-50 font-noto h3 text-light "
                          >
                            wishlist
                          </span>
            </div>
            <div style={{padding:'20px'}}>
            <div className='row' >
            {favs?.fav.map((item, index)=>{
              console.log(item,'fav')
                return(
                 
                        <div className="mb-3 col-3" key={index} style={{marign:'0 5px'}}>
                          <div className="category-artist text-decoration-none">
                            <Link to={`/artist/${item?.artist?.id}/${item?.artist?.name}`}>
                              <div className="artist-info">
                                <div className="artist-image">
                                  {item.artist.image ? (
                                    <img
                                      alt=""
                                      src={item?.artist.image?.includes("storage")?`${api2 + item?.artist.image}`:`${api + item?.artist.image}`}
                                      className=""
                                    />
                                  ) : (
                                    <img alt="" src={music} className="" />
                                  )}
                                </div>
                                <div className="artist-about font-noto-m">
                                  {_.truncate(item.artist.description, {
                                    length: 80,
                                    separator: " ",
                                  })}
                                </div>
                              </div>
                              <div className="artist-name my-2">
                                <h5 className="m-0 font-noto text-light">
                                  {_.truncate(item.artist.name, {
                                    length: 20,
                                    separator: " ",
                                  })}
                                </h5>
                              </div>
                            </Link>
                           
            
                          </div>
                        </div>
                    
                )
                
            }
            )
            }
          </div>
          </div>
       </div>
    </>
   
  )
}

export default Wishlist