import React, { useEffect, useState } from "react";
import ArtistCard from "./ArtistCard";
import { getArtist } from "../../../httpService/artists";
import Deletepopup from "./Deletepopup";
import { Link } from "react-router-dom";
import Editpopup from "./Editpopup";
import Detailspopup from "./Detailspopup";
import pin from "../../../assets/pin.png";
import geust from "../../../assets/as.png";

function HistoryCard({ event, type }) {
  const [artists, setArtists] = useState([]);
  const [toggle, setToggle] = useState(false);
  const [popup, setPopUp] = useState(false);
  const [offer, setOffer] = useState(false);
  const [details, setDetails] = useState(false);

  function dateformate(date) {
    let d = date.split("T", 1);
    return d[0];
  }
  const handletoggle = () => {
    setToggle(!toggle);
  };
  const deletepopup = () => {
    setOffer(false);
    setPopUp(true);
  };
  console.log(event);
  useEffect(() => {
    async function getartists() {
      let a = [];

      await event.artists.map(async (art) => {
        const res = await getArtist(art);

        a.push(res);

        setArtists(artists.concat(a));
      });
    }
    getartists();
  }, []);
  console.log(event.offers[0].artists[0]);
  return (
    <>
      {artists.length != 0 && (
        <div className="history-card">
          <div className="row">
            <div style={{ display: "inline-grid" }}>
              <div>
                {(type == "confirmed" || type == "Active") && (
                  <div
                    //className="artist-card"
                    style={{ display: "flex", margin: "0 2px" }}
                  >
                    <ArtistCard artist={artists[0]}></ArtistCard>
                  </div>
                )}
              </div>
            </div>
            <div className="col">
              <span className="history-card-title">{event.event_name}</span>
              <span className="history-card-date">
                {event?.description ? event.description : "TBD"}
              </span>
            </div>
            <div className="col-1" style={{ display: "block" }}>
              <div style={{ float: "right", display: "grid" }}>
                <div>
                  <button className="toggle" onClick={handletoggle}>
                    <span className="history-dots"></span>
                    <span className="history-dots"></span>
                    <span className="history-dots"></span>
                  </button>
                </div>
                {toggle && (
                  <div
                    className="history-drop-down"
                    style={{ cursor: "pointer" }}
                    data-toggle="modal"
                    data-target={`#cart-modal${event.id}`}
                    id={`#cart-modal${event.id}`}
                    onClick={() => deletepopup()}
                  >
                    Delete
                  </div>
                )}
                {!toggle && <div></div>}
              </div>
            </div>
          </div>
          {type != "previous" ? (
            <div
              className="row"
              style={{
                marginTop: "10px",
                display: "flex",
                justifyContent: "flex-end",
                padding: "0 20px",
              }}
            >
              <button
                style={{
                  border: "3px solid #FFFFFF",
                  borderRadius: "30px",
                  opacity: "1",
                  backgroundColor: "transparent",
                  color: "#FF671D",
                  padding: "5px",
                  marginRight: "20px",
                  width: "164px",
                  cursor: "pointer",
                }}
                data-toggle="modal"
                data-target={`#cart-modal${event.id}`}
                id={`#cart-modal${event.id}`}
                onClick={() => {
                  setDetails(true);
                  setOffer(false);
                  setPopUp(false);
                }}
              >
                More Details
              </button>
              <button
                data-toggle="modal"
                data-target={`#cart-modal${event.id}`}
                id={`#cart-modal${event.id}`}
                onClick={() => {
                  setOffer(true);
                  setDetails(false);

                  setPopUp(false);
                }}
                style={{
                  background: "#FFFFFF 0% 0% no-repeat padding-box",
                  border: "1px solid #707070",
                  borderRadius: "30px",
                  opacity: "1",
                  cursor: "pointer",
                  width: "164px",
                  color: "#FF671D",
                }}
              >
                Intersted
              </button>
            </div>
          ) : (
            <div>
              <div>
                <span style={{ color: "#FF671D", fontSize: "14px" }}>
                  Featured Artists:
                </span>
              </div>
              <div className="swiper-wrapper">
                {event?.offers[0].artists?.map((art, index) => {
                  return (
                    <span
                      style={{
                        font: "12px",
                        color: "white",
                        margin: "0 4px",
                      }}
                      key={index}
                    >
                      {art.artist.name}
                    </span>
                  );
                })}
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  color: "white",
                  fontSize: "14px",
                  marginTop: "20px",
                }}
              >
                <div>
                  <img
                    src={pin}
                    style={{ height: "20px", width: "20px" }}
                  ></img>
                  <span style={{ margin: "0 5px" }}>{event?.address}</span>
                  <img
                    src={geust}
                    style={{
                      height: "20px",
                      width: "20px",
                      marginLeft: "50px",
                    }}
                  ></img>
                  <span style={{ margin: "0 5px" }}>{event?.guests}</span>
                </div>
                <div>Date:{event.date ? dateformate(event.date) : "TBD"}</div>
              </div>
            </div>
          )}

          {offer && (
            <div>
              <Editpopup event={event} setOffer={setOffer}></Editpopup>
            </div>
          )}
          {popup && (
            <div>
              <Deletepopup id={event.id} setPopUp={setPopUp}></Deletepopup>
            </div>
          )}
          {details && (
            <div>
              <Detailspopup event={event}></Detailspopup>
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default HistoryCard;
