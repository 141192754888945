const initialState = {
  login: {
    email: "",
    password: "",
    error: "",
    remember: false,
    secure: true,
  },
  contactUs: {
    email: "",
    name: "",
    message: "",
    error: "",
  },
  register: {
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    password_confirmation: "",
    type: "user",
    is_company: 0,
    company_name: "",
    description: "",
    location: "",
    error: "",
    secure: true,
  },
  forgetPassword: {
    email: "",
    code: "",
    password: "",
    password_confirmation: "",
    error: "",
    secure: false,
  },
  fbLogin: {},
  googleLogin: {},
};
export default initialState;
