import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import _ from "lodash";
import getCategory2 from "../../httpService/categories";
import music from "../../assets/channels4.jpg";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import { onSetDisLiked, onSetLiked } from "../../store/Favorites/actions";
import { UiActions } from "../../store/Ui/index.js";
import {
  onFilterArtists,
  onLoadArtists,
  onLoadCategory,
} from "../../store/Categories/actions";
import {
  addArtistToCart,
  onRemoveArtistFromCart,
} from "../../store/Events/actions";
import MusicRadio from "../../UICore/Category/MusicRadio";
import PresnseRadioButtons from "../../UICore/Category/PresenseRadio";
import Reservepopup from "./Reservepopup";
import { search } from "../../httpService/artists";

const CategoryDetails = (props) => {
  const api = process.env.REACT_APP_IMAGE_URI;
  const api2 = process.env.REACT_APP_IMAGE_URI2;
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const { logged } = useSelector((state) => state.UI);
  const [Popup, setPopup] = useState(true);
  const { category, selectedArtists, filter, selectedFilteredArtists } =
    useSelector((state) => state.Categories);
  const { favourites } = useSelector((state) => state.Favorites);
  const { cart } = useSelector((state) => state.Events);
  const { FilterSelectedArtists } = useSelector((state) => state.Artists);
  const [filters, setFilter] = useState({ mainstream: 0, inter: 0 });
  const [searchedArtist, setSearchedArtist] = useState("");
  useEffect(() => {
    dispatch(onFilterArtists(selectedArtists, filter));
  }, [selectedArtists, filter]);
  useEffect(() => {
    document.addEventListener("scroll", trackScrolling);
  });
  useEffect(() => {
    if (loading === true) {
      // load artists
      if (page < category?.artists?.last_page) {
        let newp = page;
        newp = ++newp;
        setPage(newp);

        dispatch(
          onLoadArtists(
            category.id,
            filters.mainstream,
            filters.inter,
            newp,
            searchedArtist
          )
        );
      }
    } else {
      setLoading(false);
    }
  }, [loading]);
  useEffect(() => {
    //console.log(window.innerHeight,'height')
    setPage(1);
    dispatch(
      onLoadCategory(
        props.match.params.id,
        filters.mainstream,
        filters.inter,
        1,
        searchedArtist
      )
    );
    //dispatch(onLoadArtists(props.match.params.id,0,0,5));
  }, [dispatch, props.match.params.id, filters]);
  console.log(category);
  console.log(selectedArtists);

  const onHandleLike = (id) => {
    dispatch(onSetLiked(id, logged));
  };
  const onDisLike = (id) => {
    dispatch(onSetDisLiked(id, logged));
  };
  const handlePageClick = (event) => {
    console.log(event.selected);
    dispatch(
      onLoadArtists(
        category.id,
        filters.mainstream,
        filters.inter,
        event.selected + 1,
        searchedArtist
      )
    );
    window.scrollTo(0, 0);
  };
  // useEffect(()=>{
  //   console.log(page,category?.artists?.last_page)
  //   if(loading && page != category?.artists?.last_page)
  //   {
  //     console.log('hi')
  //     dispatch(onLoadArtists(category.id,filters.mainstream,filters.inter ,(page+1),searchedArtist));
  //     setPage(page+1)
  //   }
  //   else
  //   {
  //     setLoading(false)
  //   }
  // },[loading])
  const handleSearch = (event) => {
    //console.log(event.target.value)
    setSearchedArtist(event.target.value);
  };
  useEffect(() => {
    dispatch(
      onLoadArtists(
        category.id,
        filters.mainstream,
        filters.inter,
        1,
        searchedArtist
      )
    );
    setPage(1);
  }, [searchedArtist]);
  const trackScrolling = () => {
    const wrappedElement = document?.getElementById("list");
    if (isBottom(wrappedElement) && loading === false) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  };

  const isBottom = (el) => {
    return el?.getBoundingClientRect()?.bottom <= window.innerHeight;
  };

  const onAddToCart = (item) => {
    dispatch(addArtistToCart(FilterSelectedArtists, item));
    console.log("hi");
    dispatch(
      UiActions.onChangeModel([{ element: "modalName", value: "Booking" }])
    );
  };
  const onRemoveFromCart = (item) => {
    //setPopup(false)

    dispatch(onRemoveArtistFromCart(FilterSelectedArtists, item));
  };
  //console.log(selectedFilteredArtists)
  return (
    <React.Fragment>
      <main>
        <main id="single-category-page">
          <div className="container" style={{ maxWidth: "90%" }}>
            <div className="category-navbar d-flex justify-content-md-between justify-content-center mb-3 flex-wrap">
              <div className="category-navbar-left">
                <div className="category-name d-flex text-white">
                  <a
                    href="/categories"
                    className="m-0 font-noto h3 text-light text-decoration-none router-link-active"
                  >
                    Categories
                  </a>
                  <div className="category-seprator d-flex align-items-end">
                    <span></span> <span></span> <span></span>
                  </div>
                  <h3 className="font-noto m-0 text-normal">
                    {props.match.params.name}
                  </h3>
                </div>
                <div className="search mt-3">
                  <input
                    type="text"
                    className="border-0 form-control"
                    id="__BVID__1017"
                    onChange={(e) => handleSearch(e)}
                  />
                  <svg
                    width="1em"
                    height="1em"
                    viewBox="0 0 20 20"
                    focusable="false"
                    role="img"
                    alt="icon"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    className="bi-search search-icon b-icon bi text-light"
                    style={{ fontSize: "150%" }}
                  >
                    <g>
                      <path
                        fillRule="evenodd"
                        d="M12.442 12.442a1 1 0 011.415 0l3.85 3.85a1 1 0 01-1.414 1.415l-3.85-3.85a1 1 0 010-1.415z"
                        clipRule="evenodd"
                      ></path>
                      <path
                        fillRule="evenodd"
                        d="M8.5 14a5.5 5.5 0 100-11 5.5 5.5 0 000 11zM15 8.5a6.5 6.5 0 11-13 0 6.5 6.5 0 0113 0z"
                        clipRule="evenodd"
                      ></path>
                    </g>
                  </svg>
                  <span
                    aria-hidden="true"
                    className="search-icon spinner-border spinner-border-sm text-light"
                    style={{ display: "none" }}
                  ></span>
                </div>
              </div>
              <div className="category-navbar-right text-normal flex-md-column flex-wrap flex-row mt-3">
                <div className="category-filter-btns  border-normal d-flex mt-3 mr-2">
                  <MusicRadio setFilter={setFilter} filters={filters} />
                </div>

                <div className="category-filter-btns border-normal d-flex mr-2">
                  <PresnseRadioButtons
                    setFilter={setFilter}
                    filters={filters}
                  />
                </div>
              </div>
            </div>
            <div className="b-overlay-wrap position-relative">
              <div className="row" id="list">
                {selectedArtists && selectedArtists.length > 0 ? (
                  selectedArtists.map((item, index) => {
                    return (
                      <React.Fragment key={index}>
                        <div
                          className="col-md-4 col-6"
                          key={index}
                          style={{ margin: "10px 0" }}
                        >
                          <div
                            className="category-artist text-decoration-none"
                            style={{
                              paddingTop: "165px",
                              paddingBottom: "165px",
                            }}
                          >
                            <Link to={`/artist/${item.id}/${item.name}`}>
                              <div className="artist-info">
                                <div className="artist-image">
                                  {item.image ? (
                                    <img
                                      alt=""
                                      src={
                                        item.image?.includes("storage")
                                          ? `${api2 + item.image}`
                                          : `${api + item.image}`
                                      }
                                      className=""
                                    />
                                  ) : (
                                    <img alt="" src={music} className="" />
                                  )}
                                </div>
                                <div className="artist-about font-noto-m">
                                  {_.truncate(item.description, {
                                    length: 80,
                                    separator: " ",
                                  })}
                                </div>
                              </div>
                              <div className="artist-name my-2">
                                <h5 className="m-0 font-noto text-light">
                                  {_.truncate(item.name, {
                                    length: 20,
                                    separator: " ",
                                  })}
                                </h5>
                              </div>
                            </Link>
                            <div className="artist-actions mt-1">
                              {cart &&
                              cart.artists &&
                              cart.artists?.length > 0 &&
                              cart.artists.findIndex((i) => i === item.id) >=
                                0 ? (
                                <>
                                  <div
                                    onClick={() => onRemoveFromCart(item)}
                                    className="b-overlay-wrap position-relative ml-1"
                                  >
                                    <i
                                      style={{
                                        width: "auto",
                                        height: "auto",
                                        fontSize: "20px",
                                      }}
                                      className="bi-cart-x bg-normal p-1 action-icon b-icon bi"
                                    />
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div
                                    style={{ cursor: "pointer" }}
                                    type="button"
                                    data-toggle="modal"
                                    data-target="#cart-modal"
                                    //className="btn h-100 ml-1 btn-normal btn-sm btn-block rounded-0"
                                    onClick={() => onAddToCart(item)}
                                    //style={{textDecoration:'none'}}

                                    className="b-overlay-wrap position-relative ml-1"
                                  >
                                    <Link
                                      target="_self"
                                      to=""
                                      style={{
                                        textDecoration: "none",
                                        color: "white",
                                      }}
                                    >
                                      <svg
                                        aria-hidden="true"
                                        width="18"
                                        height="30"
                                        viewBox="-50 0 520 512"
                                        focusable="false"
                                        style={{
                                          width: "auto",
                                          height: "auto",
                                          fontSize: "20px",
                                          color: "white",
                                        }}
                                        className="bg-normal p-1 action-icon b-icon bi"
                                      >
                                        <g>
                                          <path
                                            d="M384 112v352c0 26.51-21.49 48-48 48H48c-26.51 0-48-21.49-48-48V112c0-26.51 21.49-48 48-48h80c0-35.29 28.71-64 64-64s64 28.71 64 64h80c26.51 0 48 21.49 48 48zM192 40c-13.255 0-24 10.745-24 24s10.745 24 24 24 24-10.745 24-24-10.745-24-24-24m96 114v-20a6 6 0 0 0-6-6H102a6 6 0 0 0-6 6v20a6 6 0 0 0 6 6h180a6 6 0 0 0 6-6z"
                                            style={{ fill: "white" }}
                                          ></path>
                                        </g>
                                      </svg>
                                    </Link>
                                  </div>
                                </>
                              )}
                              {
                                <div
                                  className="b-overlay-wrap position-relative ml-1"
                                  data-toggle={!logged && "modal"}
                                  data-target={
                                    !logged && "#auth-required-modal"
                                  }
                                >
                                  {favourites &&
                                  favourites.findIndex(
                                    (i) => i?.artist_id === item?.id
                                  ) >= 0 ? (
                                    <div
                                      style={{ height: "100%" }}
                                      onClick={() => onDisLike(item.id)}
                                    >
                                      <div
                                        style={{
                                          width: "100%",
                                          height: "100%",
                                          fontSize: "18px",
                                        }}
                                        className="bi-heart-fill bg-normal p-1 action-icon b-icon bi"
                                      ></div>
                                    </div>
                                  ) : (
                                    <div
                                      style={{ height: "100%" }}
                                      onClick={() => onHandleLike(item.id)}
                                    >
                                      <div
                                        style={{
                                          width: "100%",
                                          height: "100%",
                                          fontSize: "18px",
                                        }}
                                        className="bi-heart bg-normal p-1 action-icon b-icon bi"
                                      ></div>
                                    </div>
                                  )}
                                </div>
                              }
                            </div>
                          </div>
                        </div>
                        <div></div>
                      </React.Fragment>
                    );
                  })
                ) : (
                  <React.Fragment>
                    <div className="row">
                      <h4 className="text-normal text-center my-3">
                        Sorry No Artists Found in{" "}
                        {/* {active1 === 0 ? " Local " : " International "} and
                        {active2 === 0 ? " Alternative " : " Mainstream "} */}
                        Filters, Please Adjust the Filters and Try Again.
                      </h4>
                    </div>
                  </React.Fragment>
                )}
              </div>
            </div>
          </div>
        </main>
      </main>
      <div id="auth-required-modal" className="modal fade">
        <div className="modal-dialog modal-md modal-dialog-centered">
          <span tabIndex="0"></span>
          <div
            role="document"
            id="auth-required-modal___BV_modal_content_"
            tabIndex="-1"
            className="modal-content"
          >
            <div
              id="auth-required-modal___BV_modal_body_"
              className="modal-body bg-dark text-light"
            >
              <div className="font-noto text-center h4">
                <strong>Login</strong> required to do this action
              </div>
            </div>
            <footer
              id="auth-required-modal___BV_modal_footer_"
              className="modal-footer bg-dark"
            >
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <a target="_self" href="/login" className="btn btn-primary">
                Login
              </a>
            </footer>
          </div>
          <span tabIndex="0"></span>
        </div>
        <div></div>
      </div>
      {/* {selectedArtists &&
        selectedArtists.length > 0 ?
        <ReactPaginate
        breakLabel="..."
        nextLabel="Next >"
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={category?.artists?.last_page}
        previousLabel="< Previous"
        renderOnZeroPageCount={null}
        containerClassName={'pagination justify-content-center'}
        pageClassName={'page-item'}
        pageLinkClassName={'page-link'}
        previousClassName={'page-item'}
        previousLinkClassName={'page-link'}
        nextClassName={'page-item'}
        nextLinkClassName={'page-link'}
        activeClassName={'active'}
      />:''} */}
    </React.Fragment>
  );
};

export default CategoryDetails;
