import http from "./httpHandler";
import _try from "../utils/try";
import handleServerError from "./handleServerError";
const route = process.env.REACT_APP_API + "/api/news";

export const getNews = _try(async () => {
  let headers;
  let token = localStorage.getItem("x-auth-token");
  if (token) headers = { Authorization: `Bearer ${token}` };
  const response = await http.get(route, { headers: headers });
  const result = handleServerError(response);
  if (result) return { data: null, error: result };
  else {
    if (response?.response?.status === 401)
      return { data: null, error: response.response.data.message };
    else return { data: response.data, error: null };
  }
});

