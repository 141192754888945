import React, { useEffect, useState } from "react";
//import {removeEvents} from '../../../httpService/event'

const Reservepopup = () => {
    
  return (
    <div
      id="cart-modal"
      role="dialog"
      aria-labelledby="cart-modal"
      aria-describedby="cart-modal"
      className="modal fade"
      aria-hidden="true"
      aria-modal="true"
      tabIndex="-1"
    >
      <div className="modal-dialog modal-lg modal-dialog-centered">
        <span tabIndex="0"></span>
        <div
          role="document"
          id="cart-modal"
          tabIndex="-1"
          className="modal-content bg-transparent border-0"
        >
          <div
            id="cart-modal"
            className="modal-body book-modal-body border-normal"
          >
          <div className="additional-services my-3" >
            <div className="flex-center">
              <p className="Bookingpopup-titleone">Artist Added Successfully</p>{" "}
            </div>
           
        </div>
       
        </div>
        </div>
      </div>
    </div>
  );
};

export default Reservepopup;
