import axios from "axios";
import React, { useEffect, useState } from "react";
import { removeEvents } from "../../../httpService/event";
const token = localStorage.getItem("x-auth-token");
const Changepass = ({ setOpenModel }) => {
  async function changePassword(data) {
    if (data.password !== data.confirm_password)
      setError("Password not matches confirm password");
    else {
      const response = await axios({
        method: "put",
        url: "https://admin.entertainme.co/api/password/change",
        headers: { Authorization: `Bearer ${token}` },
        data,
      });
      console.log(response);
      if (response.status === 200 || response.status === 2001) {
        window.location.href = "/history";
        setError("");
      } else {
        setError(response.response.data.message);
      }
    }
  }
  console.log(token);
  const handleinput = (e) => {
    let key = e.target.name;
    let value = e.target.value;
    setPassword({ ...password, [key]: value });
  };
  const [password, setPassword] = useState({
    password: "",
    confirm_password: "",
  });
  const [error, setError] = useState("");
  return (
    <div
      id="pass-modal"
      role="dialog"
      aria-labelledby="cart-modal"
      aria-describedby="cart-modal"
      className="modal fade"
      aria-hidden="true"
      aria-modal="true"
      tabIndex="-1"
    >
      <div className="modal-dialog modal-lg modal-dialog-centered">
        <span tabIndex="0"></span>
        <div
          role="document"
          id="cart-modal"
          tabIndex="-1"
          className="modal-content bg-transparent border-0"
        >
          <div
            id="cart-modal"
            className="modal-body book-modal-body border-normal"
          >
            <div className="additional-services my-3">
              <div className="flex-center">
                <p className="Bookingpopup-titleone">Change password</p>{" "}
              </div>
              <div style={{ marginBottom: "10px" }}>
                <label className="row">Enter password</label>
                <input
                  style={{
                    border: "1px solid #f70",
                    borderRadius: "20px",
                    color: "black",
                    width: "100%",
                    padding: "10px",
                  }}
                  type="password"
                  name="password"
                  onChange={(e) => handleinput(e)}
                ></input>
              </div>
              <div style={{ marginBottom: "10px" }}>
                <label className="row">Confirm password</label>
                <input
                  style={{
                    border: "1px solid #f70",
                    borderRadius: "20px",
                    color: "black",
                    width: "100%",
                    padding: "10px",
                  }}
                  type="password"
                  name="confirm_password"
                  placeholder="Confirm password...."
                  onChange={(e) => handleinput(e)}
                ></input>
              </div>
              <div
                className="row flex-center"
                style={{ display: "flex", color: "red", marginBottom: "5px" }}
              >
                {error}
              </div>
              <div
                className="flex-center"
                style={{
                  margin: "auto",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <div className=" flex-center">
                  <button
                    className="booking-pop-up-btn"
                    onClick={() => changePassword(password)}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Changepass;
