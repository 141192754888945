import React, { useEffect, useState } from "react";
import { getUserData } from "../../../httpService/event";
import Changepass from "./Changepass";
import HistoryCard from "./HistoryCard";
import settings from "../../../assets/Group 2.png";
import pen from "../../../assets/pencil-solid.svg";
import axios from "axios";
function History() {
  const [events, setEvents] = useState([]);
  const [error, setError] = useState("");
  const [openmodel, setOpenModel] = useState(false);
  const [confirmed, setConfirmed] = useState(true);
  const [selectedImage, setSelectedImage] = useState(null);
  const [active, setActive] = useState(false);
  const [user, setUser] = useState({});
  const [previous, setPrevious] = useState(false);
  const name = localStorage.getItem("user");
  const [type, setType] = useState("confirmed");
  const token = localStorage.getItem("x-auth-token");

  async function changeprofile(event) {
    const formData = new FormData();
    formData.append("first_name", user.first_name);
    formData.append("last_name", user.last_name);
    formData.append("image", event.target.files[0]);
    for (var pair of formData.entries()) {
      console.log(pair[0] + ", " + pair[1]);
    }
    const result = await axios({
      method: "post",
      url: "https://admin.entertainme.co/api/profile",
      headers: { Authorization: `Bearer ${token}` },
      data: formData,
    });
    if (result.error) {
      setError(result.error);
    } else {
      window.location.href = "/history";
    }
  }

  const handlebtn = (type) => {
    if (type == "confirmed") {
      //getevents("confirmed");
      setType("confirmed");
      setConfirmed(true);
      setActive(false);
      setPrevious(false);
    } else if (type == "active") {
      //getevents("active");
      setType("active");
      setConfirmed(false);
      setActive(true);
      setPrevious(false);
    } else if (type == "previous") {
      //getevents("previous");
      setType("previous");
      setConfirmed(false);
      setActive(false);
      setPrevious(true);
    }
  };
  console.log(openmodel);
  async function getevents(type) {
    const result = await getUserData(type);
    if (result.error) {
      setError(result.error);
    } else {
      console.log(result.data);
      setEvents(result.data.orders);
      setUser(result.data);
    }
  }
  useEffect(() => {
    getevents(type);
  }, [type]);
  console.log(events, "events");
  return (
    <>
      <div className="history-page">
        <div className="history-hero-container container-fluid">
          <div
            className="history-hero-container-pp"
            style={{ width: "fit-content" }}
          >
            <label>
              <img
                className="history-hero-profile container-fluid"
                src={
                  user.image
                    ? `https://admin.entertainme.co/storage/${user.image}`
                    : selectedImage
                    ? URL.createObjectURL(selectedImage)
                    : pen
                }
                //className="image-input-wrapper w-125px h-125px"
              ></img>

              <input
                type="file"
                name="company_logo"
                accept=".png, .jpg, .jpeg"
                style={{ display: "none" }}
                onChange={(event) => {
                  console.log(event.target.files[0], "79");

                  changeprofile(event);
                }}
              />
            </label>
            {/* <input type="hidden" name="avatar_remove" /> */}
          </div>
          <div className="history-hero-profile-info">
            <div>
              <h3 className="history-hero-profile-name">
                {user?.first_name} {user?.last_name}
              </h3>
              <div className="history-hero-profile-desc">
                {user?.description}
              </div>
            </div>
            <div style={{ marginTop: "28px", marginInlineEnd: "28px" }}>
              <img
                src={settings}
                style={{ width: "27px", cursor: "pointer" }}
                data-toggle="modal"
                data-target="#pass-modal"
                onClick={() => setOpenModel(true)}
              ></img>
            </div>
          </div>
        </div>
      </div>
      <div
        className="flex-center history-card-reserved"
        style={{
          borderBottom: "2px solid #707070",
          width: "60%",

          margin: "auto",
        }}
      >
        <button
          className={
            confirmed
              ? "history-hero-profile-btn-active"
              : "history-hero-profile-btn"
          }
          onClick={() => handlebtn("confirmed")}
        >
          Confirmed projects
        </button>
        <button
          className={
            active
              ? "history-hero-profile-btn-active"
              : "history-hero-profile-btn"
          }
          style={{ margin: "0 20px" }}
          onClick={() => handlebtn("active")}
        >
          Active projects
        </button>
        <button
          className={
            previous
              ? "history-hero-profile-btn-active"
              : "history-hero-profile-btn"
          }
          onClick={() => handlebtn("previous")}
        >
          Previous projects
        </button>
      </div>
      <div className="flex-center title-project">
        <span>{type} Projects</span>
      </div>
      {events.length != 0 && (
        <>
          <div
            className="flex-center "
            style={{ display: "grid", margin: "auto" }}
          >
            {events.map((event, index) => {
              return (
                <div key={index}>
                  <HistoryCard event={event} type={type}></HistoryCard>
                </div>
              );
            })}
          </div>
        </>
      )}
      {openmodel && <Changepass setOpenModel={setOpenModel}></Changepass>}
      {events.length == 0 && (
        <div
          className="flex-center"
          style={{ color: "#FE681D", fontSize: "24px" }}
        >
          No Events Found
        </div>
      )}
    </>
  );
}

export default History;
