import React, { useEffect, useState } from "react";

import music from "../../assets/channels4.jpg";
import slideImage1 from "../../assets/slideImage1.jpg";
import slideImage2 from "../../assets/slideImage2.jpg";
import slideImage3 from "../../assets/slideImage3.jpg";
import { UiActions } from "../../store/Ui/index.js";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from "swiper";
// Import Swiper styles
import "swiper/swiper-bundle.css";
//import 'swiper/modules/pagination/pagination.min.css'
import { useDispatch, useSelector } from "react-redux";
import { getArtist as httpGetArtist } from "../../httpService/categories";
import { ArtistsActions } from "../../store/Artists";
import { onSetDisLiked, onSetLiked } from "../../store/Favorites/actions";
import { addArtistToCart } from "../../store/Events/actions";

import "./categories.css";
const Artist = (props) => {
  SwiperCore.use([Navigation]);
  const [api] = useState(process.env.REACT_APP_IMAGE_URI);
  const [api2] = useState(process.env.REACT_APP_IMAGE_URI2);
  //const api = process.env.REACT_APP_IMAGE_URI;
  console.log(api);
  const dispatch = useDispatch();
  const { FilterSelectedArtists } = useSelector((state) => state.Artists);
  const artist = useSelector((state) => state.Artists.artist);
  const { favourites } = useSelector((state) => state.Favorites);
  const { logged } = useSelector((state) => state.UI);
  const work_image = [];
  const work_videos = [];
  const work_audio = [];
  useEffect(() => {
    async function fetch() {
      dispatch(
        ArtistsActions.onChangeArtist({
          data: await httpGetArtist(props.match.params.id),
        })
      );
    }
    fetch();
  }, [dispatch, props.match.params.id]);
  const onHandleLike = () => {
    dispatch(onSetLiked(props?.match?.params?.id, logged));
  };
  const onDisLike = () => {
    dispatch(onSetDisLiked(props?.match?.params?.id, logged));
  };
  const onAddToCart = (item) => {
    dispatch(addArtistToCart(FilterSelectedArtists, item));
    dispatch(
      UiActions.onChangeModel([{ element: "modalName", value: "Booking" }])
    );
  };
  function prepare_media(artist) {
    for (let i = 0; i < artist.works.length; i++) {
      if (artist.works[i].type == "image") {
        work_image.push(artist.works[i].content);
      } else if (artist.works[i].type == "video") {
        work_videos.push(artist.works[i].content);
      }
      else{
        work_audio.push(artist.works[i].content);
      }
    }
  }
  prepare_media(artist);
  //console.log(work_image)
  //console.log(work_videos)
  console.log(artist);
  console.log(api + artist.image);

  return (
    <main>
      <main id="single-artist-page">
        <div className="b-overlay-wrap position-relative">
          <div className="py-3 container">
            <div className="category-name d-flex text-white flex-wrap">
              <a
                href="/categories"
                className="m-0 h3 font-noto text-decoration-none text-light"
              >
                Categories
              </a>
              <div className="category-seprator d-flex align-items-end">
                <span></span> <span></span> <span></span>
              </div>
              <a
                href={`/categoryDetails/${artist?.category.id}/${artist?.category.name}`}
                className="font-noto m-0 h3 text-decoration-none text-light"
              >
                {artist?.category.name}
              </a>
              <div className="category-seprator d-flex align-items-end">
                <span></span> <span></span> <span></span>
              </div>
              <h3 className="font-noto m-0 text-normal">{artist?.name}</h3>
            </div>
            <div
              className="row mt-3"
              style={{
                position: "-webkit-sticky",
                position: "sticky",
                top: "0px",
                backgroundColor: "#0d0d0d",
                zIndex: "9",
              }}
            >
              <div className="col-md-4">
                <div className="single-artist text-decoration-none">
                  <div className="artist-info">
                    <div className="artist-image">
                      {artist?.image ? (
                        <img
                          alt=""
                          src={
                            artist.image?.includes("storage")
                              ? `${api2 + artist.image}`
                              : `${api + artist.image}`
                          }
                          className=""
                        />
                      ) : (
                        <img alt="" src={music} className="" />
                      )}
                    </div>
                  </div>
                  <div className="artist-actions mt-3 align-items-center">
                    <div
                      className="b-overlay-wrap position-relative ml-1"
                      data-toggle={!logged && "modal"}
                      data-target={!logged && "#auth-required-modal"}
                    >
                      {favourites &&
                      favourites.findIndex(
                        (item) => item.artist_id === artist.id
                      ) >= 0 ? (
                        <div onClick={() => onDisLike()}>
                          <div
                            style={{
                              width: "100%",
                              height: "100%",
                              fontSize: "18px",
                            }}
                            className="bi-heart-fill bg-normal p-1 action-icon b-icon bi"
                          ></div>
                        </div>
                      ) : (
                        <div onClick={() => onHandleLike()}>
                          <div
                            style={{
                              width: "100%",
                              height: "100%",
                              fontSize: "18px",
                            }}
                            className="bi-heart bg-normal p-1 action-icon b-icon bi"
                          ></div>
                        </div>
                      )}
                    </div>

                    <button
                      type="button"
                      data-toggle="modal"
                      data-target="#cart-modal"
                      className="btn h-100 ml-1 btn-normal btn-sm btn-block rounded-0"
                      onClick={() => onAddToCart(artist)}
                      style={{ textDecoration: "none" }}
                    >
                      <Link
                        target="_self"
                        to=""
                        style={{ textDecoration: "none", color: "white" }}
                      >
                        Add to Booking
                      </Link>
                    </button>
                  </div>
                </div>
                <h2 className="text-center mt-3 font-noto text-light">
                  {artist?.name}
                </h2>
              </div>
              <div className="col-md-8">
                <div className="card rounded-0 single-artist-about px-lg-3">
                  <div className="card-body">
                    <h3 className="text-normal font-noto">About</h3>
                    <p className="text-light font-noto-m">
                      {artist?.description}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-md-2">
                <h5 className="text-normal font-noto-m works-section-title">
                  <span></span>
                  Videos
                </h5>
              </div>
              <div className="col-md-10">
                <section className="artist-works">
                  <Swiper
                    spaceBetween={10}
                    slidesPerView={1}
                    navigation
                    tag="section"
                    wrapperTag="ul"
                    onSlideChange={() => console.log("slide change")}
                    onSwiper={(swiper) => console.log(swiper)}
                  >
                    {work_videos.map((video, id) => {
                      return (
                        <SwiperSlide tag="li">
                          <div
                            style={{
                              justifyContent: "center",
                              marginLeft: "-3rem",
                              display: "flex",
                              marginTop: "0.5rem",
                            }}
                            dangerouslySetInnerHTML={{ __html: video }}
                          ></div>
                        </SwiperSlide>
                      );
                    })}
                  </Swiper>
                </section>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-md-2">
                <h5 className="text-normal font-noto-m works-section-title">
                  <span></span>
                  Audios
                </h5>
              </div>
              <div className="col-md-10">
                <section className="artist-works">
                  <Swiper
                    spaceBetween={10}
                    slidesPerView={1}
                    navigation
                    tag="section"
                    wrapperTag="ul"
                    onSlideChange={() => console.log("slide change")}
                    onSwiper={(swiper) => console.log(swiper)}
                  >
                    {work_audio.map((video, id) => {
                      return (
                        <SwiperSlide tag="li">
                          <div
                            style={{
                              justifyContent: "center",
                              marginLeft: "-3rem",
                              display: "flex",
                              marginTop: "0.5rem",
                            }}
                            dangerouslySetInnerHTML={{ __html: video }}
                          ></div>
                        </SwiperSlide>
                      );
                    })}
                  </Swiper>
                </section>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-md-2">
                <h5 className="text-normal font-noto-m works-section-title">
                  <span></span>
                  Images
                </h5>
              </div>
              <div className="col-md-10">
                <section className="artist-works">
                  <Swiper
                    spaceBetween={10}
                    slidesPerView={1}
                    navigation
                    tag="section"
                    onSlideChange={() => console.log("slide change")}
                    onSwiper={(swiper) => console.log(swiper)}
                  >
                    {work_image.map((img, id) => {
                      return (
                        <SwiperSlide>
                          <img
                            className="flex-center"
                            style={{
                              height: "inherit",
                              width: "max-content",
                              margin: "auto",
                            }}
                            src={
                              img?.includes("storage")
                                ? `${api2 + img}`
                                : `${api}${img}`
                            }
                          ></img>
                        </SwiperSlide>
                      );
                    })}
                  </Swiper>
                </section>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-md-2">
                <h5 className="text-normal font-noto-m works-section-title">
                  <span></span>
                  Previous Events
                </h5>
              </div>
              <div className="col-md-10">
                <div className="card rounded-0 previous-events  h-100 px-lg-3">
                  <div className="card-body">
                    <div className="d-flex flex-wrap">
                      {artist?.events.map((item, index) => {
                        return (
                          <div
                            className="mb-3 mr-3 text-light font-noto single-prev-event"
                            key={index}
                          >
                            <svg
                              width="1em"
                              height="1em"
                              viewBox="0 0 20 20"
                              focusable="false"
                              role="img"
                              alt="icon"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="currentColor"
                              className="bi-three-dots dots b-icon bi"
                            >
                              <g transform="translate(10 10) scale(9 9) translate(-10 -10)">
                                <path
                                  fillRule="evenodd"
                                  d="M5 11.5a1.5 1.5 0 110-3 1.5 1.5 0 010 3zm5 0a1.5 1.5 0 110-3 1.5 1.5 0 010 3zm5 0a1.5 1.5 0 110-3 1.5 1.5 0 010 3z"
                                  clipRule="evenodd"
                                ></path>
                              </g>
                            </svg>
                            <span>{item.name}</span>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </main>
  );
};
export default Artist;
