import React from "react";
import Logo from "../UIs/logo";

const Laoding = () => {
  return (
    <React.Fragment>
      <div className="main-loading">
       <Logo width='450' height='200'/>
      </div>
    </React.Fragment>
  );
};

export default Laoding;
